import React from 'react';
import Seo from '../components/Seo';
import SiteLayout from '../components/layout/layout';

import { H1, TextRegular } from '../components/shared/typography';

function NotFoundPage() {

  return (
    <SiteLayout locale="da" componentName="404">
      <Seo title="404: Not found" pathname="404" />
      <H1>{`NOT FOUND`}</H1>
      <TextRegular>{`You just hit a route that doesn't exist... the sadness.`}</TextRegular>
    </SiteLayout>
  );
}

export default NotFoundPage;
